/**
 * ./stores/QuickActivityForm.ts
 */

export const useQuickActivityFormStore = defineStore('quickActivityForm', () => {
    const show = ref<boolean>(false);
    const activeAccount = ref<IAccount|null>();
    const activeContacts = ref<string[]>([]);

    const setActiveAccount = (account: IAccount) => {
        activeAccount.value = account;
    }

    const unsetActiveAccount = () => {
        activeAccount.value = null;
    }

    const setActiveContacts = (contacts: IContact[]) => {
        activeContacts.value = contacts.map((contact) => contact.id);
    }

    const unsetActiveContacts = () => {
        activeContacts.value = [];
    }

    const toggle = () => {
        show.value = ! show.value;
    }
    
    return {
        show,
        activeAccount,
        activeContacts,
        setActiveAccount,
        unsetActiveAccount,
        setActiveContacts,
        unsetActiveContacts,
        toggle,
    }
});